/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Delivery API
 * This API manages the projects and docuements that are stored in the Ameta Document Delivery. The API used oAuth2 flow for authorization.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShareProjectUserAccess
 */
export interface ShareProjectUserAccess {
    /**
     * 
     * @type {string}
     * @memberof ShareProjectUserAccess
     */
    userEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareProjectUserAccess
     */
    accessType?: string;
}

export function ShareProjectUserAccessFromJSON(json: any): ShareProjectUserAccess {
    return ShareProjectUserAccessFromJSONTyped(json, false);
}

export function ShareProjectUserAccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShareProjectUserAccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userEmail': !exists(json, 'userEmail') ? undefined : json['userEmail'],
        'accessType': !exists(json, 'accessType') ? undefined : json['accessType'],
    };
}

export function ShareProjectUserAccessToJSON(value?: ShareProjectUserAccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userEmail': value.userEmail,
        'accessType': value.accessType,
    };
}


