/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Delivery API
 * This API manages the projects and docuements that are stored in the Ameta Document Delivery. The API used oAuth2 flow for authorization.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeoJSONLocation,
    GeoJSONLocationFromJSON,
    GeoJSONLocationFromJSONTyped,
    GeoJSONLocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateDocument
 */
export interface UpdateDocument {
    /**
     * 
     * @type {string}
     * @memberof UpdateDocument
     */
    documentName?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDocument
     */
    documentSize?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocument
     */
    documentS3Href?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocument
     */
    documentType?: string;
    /**
     * 
     * @type {GeoJSONLocation}
     * @memberof UpdateDocument
     */
    documentLocation?: GeoJSONLocation;
}

export function UpdateDocumentFromJSON(json: any): UpdateDocument {
    return UpdateDocumentFromJSONTyped(json, false);
}

export function UpdateDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentName': !exists(json, 'documentName') ? undefined : json['documentName'],
        'documentSize': !exists(json, 'documentSize') ? undefined : json['documentSize'],
        'documentS3Href': !exists(json, 'documentS3Href') ? undefined : json['documentS3Href'],
        'documentType': !exists(json, 'documentType') ? undefined : json['documentType'],
        'documentLocation': !exists(json, 'documentLocation') ? undefined : GeoJSONLocationFromJSON(json['documentLocation']),
    };
}

export function UpdateDocumentToJSON(value?: UpdateDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentName': value.documentName,
        'documentSize': value.documentSize,
        'documentS3Href': value.documentS3Href,
        'documentType': value.documentType,
        'documentLocation': GeoJSONLocationToJSON(value.documentLocation),
    };
}


