import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import { TStore } from "redux/store";
import Box from "@mui/material/Box";

import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";

import GridLayout from "@smartinspection/ameta-frontend-components/layout/GridLayout";

import TopView from "components/TopView";

import Text from "@smartinspection/ameta-frontend-components/display/Text";

import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";

import ProjectDetailsCard from "components/project/ProjectDetailsCard";
import ProjectLocationCard from "components/project/ProjectLocationCard";
import ProjectDocumentsCard from "components/project/documentsCard/ProjectDocumentsCard";
import ProjectPointCloudsCard from "components/project/pointcloudsCard/ProjectPointcloudsCard";

import {
  useQueryProjects,
  useQueryProjectById,
} from "services/deliveryQueryHooks";

export default function Project() {
  const globalState = useSelector((state: TStore) => state.global);

  const pathParams = useParams();
  const projectId = pathParams.projectId ? pathParams.projectId : "";

  const projectQuery = useQueryProjectById(projectId);
  const project = projectQuery.data;
  const projectsQuery = useQueryProjects();
  const projects = projectsQuery.data;

  const [projectAccess, setProjectAccess] = useState("reader");

  useEffect(() => {
    projects &&
      projects.forEach((projectElement) => {
        if (projectId === projectElement.sort) {
          setProjectAccess(projectElement.accessType);
        }
      });
  }, [projects, projectId]);

  let pageContent: React.ReactNode = null;

  if (projectsQuery || projectQuery.isLoading) {
    pageContent = (
      <CenterContainer>
        <CircularProgress size={150} />
      </CenterContainer>
    );
  }

  if (
    projects &&
    !projects.find((project) => {
      return projectId === project.sort;
    })
  ) {
    pageContent = (
      <CenterContainer>
        <Text variant="h3">Kunne ikke finne prosjektet</Text>
      </CenterContainer>
    );
  } else if (project) {
    pageContent = (
      <GridLayout
        spacing={16}
        editable={globalState.layoutEditable}
        layout={[
          {
            i: "projectOwnerCard",
            h: 10,
            w: 6,
            x: 0,
            y: 0,
          },
          {
            i: "projectDocumentsCard",
            h: 18,
            w: 6,
            x: 6,
            y: 0,
          },
          {
            i: "projectLocationCard",
            h: 20,
            w: 6,
            x: 0,
            y: 10,
          },
          {
            i: "projectPointCloudsCard",
            h: 12,
            w: 6,
            x: 6,
            y: 18,
          },
        ]}
      >
        <ProjectDetailsCard
          key="projectOwnerCard"
          editable={projectAccess === "owner"}
          project={project}
        />

        <ProjectLocationCard
          key="projectLocationCard"
          editable={projectAccess === "owner"}
          project={project}
          refetch={projectQuery.refetch}
        />

        <ProjectDocumentsCard
          key="projectDocumentsCard"
          projectId={projectId}
          editable={projectAccess === ("owner" || "contributor")}
        />

        <ProjectPointCloudsCard
          key="projectPointCloudsCard"
          projectId={projectId}
        />
      </GridLayout>
    );
  }

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TopView title="Prosjektoversikt" />
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
        }}
      >
        {pageContent}
      </Box>
    </Box>
  );
}
