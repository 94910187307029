import React from "react";
import { Auth } from "aws-amplify";
import { queryClient } from "services/apiService";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toggleHideMenus } from "redux/global";
import { TStore } from "redux/store";
import SINavBar from "@smartinspection/ameta-frontend-components/navigation/NavBar";

import AddLocationIcon from "@mui/icons-material/AddLocation";
import MapIcon from "@mui/icons-material/Map";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SettingsIcon from "@mui/icons-material/Settings";

export default function NavBarWrapper() {
  const navigate = useNavigate();
  const globalState = useSelector((state: TStore) => state.global);
  const dispatch = useDispatch();

  return (
    <SINavBar
      hideMenu={globalState.hideMenus}
      onMinimizeClick={() => {
        dispatch(toggleHideMenus());
        setTimeout(() => {
          window.dispatchEvent(new Event("resize"));
        }, 300);
      }}
      avatarMenuItems={[
        {
          label: "Logg ut",
          onClick: () => {
            Auth.signOut().then(() => {
              queryClient.clear();
            });
          },
        },
      ]}
      menuItems={[
        {
          title: "Lag Prosjekt",
          icon: AddLocationIcon,
          path: "/dashboard/new-project",
          onClick: () => {
            navigate("/dashboard/new-project");
          },
        },
        {
          title: "Kart",
          icon: MapIcon,
          path: "/dashboard/map",
          onClick: () => {
            navigate("/dashboard/map");
          },
        },
        {
          title: "Listeoversikt",
          icon: ListAltIcon,
          path: "/dashboard/list-view",
          onClick: () => {
            navigate("/dashboard/list-view");
          },
        },
        {
          title: "Innstillinger",
          icon: SettingsIcon,
          path: "/dashboard/settings",
          onClick: () => {
            navigate("/dashboard/settings");
          },
        },
      ]}
    />
  );
}
