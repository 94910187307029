import React, { useState } from "react";

import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LatLng } from "leaflet";

import Card from "@smartinspection/ameta-frontend-components/display/Card";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";
import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import Tooltip from "@smartinspection/ameta-frontend-components/display/Tooltip";
import LocationPicker from "@smartinspection/ameta-frontend-components/pickers/LocationPicker";

import { Project } from "services/deliveryApi/models/Project";
import { authorizedProjectApi } from "services/apiService";
import { notify } from "@smartinspection/ameta-frontend-components/utils/notification";

export default function ProjectDescriptionLocationCard({
  editable,
  className,
  project,
  disabled,
  refetch,
}: {
  editable?: boolean;
  project?: Project;
  className?: string;
  disabled?: boolean;
  refetch?: () => void;
}) {
  const [location, setLocation] = useState<LatLng | undefined>(
    project?.projectLocation
      ? new LatLng(
          Number(project.projectLocation.geometry.coordinates[1]),
          Number(project.projectLocation.geometry.coordinates[0])
        )
      : undefined
  );

  const [editEnabled, setEditEnabled] = useState(false);

  function onSaveClick() {
    project &&
      location &&
      authorizedProjectApi().then((api) => {
        api
          .updateProject({
            projectId: project.id,
            updateProject: {
              projectLocation: {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [String(location.lng), String(location.lat)],
                },
                properties: {},
              },
            },
          })
          .then(() => {
            notify({ message: "Lokasjon oppdatert", type: "success" });
          });
      });
  }

  return (
    <Card
      type="drawer"
      title="Lokasjon"
      faIcon="location-crosshairs"
      disabled={disabled}
      rightHeaderComponent={
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            pr: 2,
          }}
        >
          {editable && (
            <React.Fragment>
              {editEnabled && (
                <Text sx={{ pr: 3 }} variant="body1">{`Klikk i kartet for å ${
                  project?.projectLocation ? "oppdatere" : "legge til"
                } lokasjon`}</Text>
              )}
              <Tooltip
                placement="top"
                tooltipContent={
                  <Text variant="body1">
                    {!editEnabled ? "Editer lokation" : "Avslutt editering"}
                  </Text>
                }
              >
                <IconButton
                  noShadow={editEnabled}
                  colored={editEnabled}
                  sx={{ mr: 1 }}
                  type="info"
                  onClick={() => {
                    if (editEnabled) {
                      setLocation(
                        project?.projectLocation
                          ? new LatLng(
                              Number(
                                project.projectLocation.geometry.coordinates[1]
                              ),
                              Number(
                                project.projectLocation.geometry.coordinates[0]
                              )
                            )
                          : undefined
                      );
                      setEditEnabled(false);
                    } else {
                      setEditEnabled(true);
                    }
                  }}
                >
                  <FontAwesomeIcon icon="edit" />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          )}
        </Box>
      }
    >
      <Box
        sx={{
          pt: 2,
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <LocationPicker
          onChange={(location) => {
            setLocation(location);
          }}
          disabled={!editEnabled}
          location={location}
        />
        {editEnabled && location && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              pt: 1,
              justifyContent: "flex-end",
            }}
          >
            {project?.projectLocation ? (
              <Button
                endIcon={<FontAwesomeIcon icon="save" />}
                type="success"
                onClick={() => {
                  onSaveClick();
                  setEditEnabled(false);
                }}
              >
                Lagre
              </Button>
            ) : (
              <Button
                endIcon={<FontAwesomeIcon icon="plus" />}
                type="success"
                onClick={() => {
                  onSaveClick();
                  setEditEnabled(false);
                }}
              >
                Opprett lokasjon
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Card>
  );
}
