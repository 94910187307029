import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import Text from "@smartinspection/ameta-frontend-components/display/Text";
import Button from "@smartinspection/ameta-frontend-components/controls/Button";

import CustomScroll from "react-custom-scroll";
import "assets/css/commonScroll.css";

const useStyles = makeStyles(() =>
  createStyles({
    root: { padding: 50 },
    question: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    buttonContainer: {
      height: "100%",
      padding: 50,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
    },
    // headCell: {
    //   backgroundColor: theme.colors.base.white,
    // },
    button: { width: 200 },
  })
);

function ConfirmSharing({
  completedSharingList,
  onCancel,
  onAccept,
}: {
  completedSharingList: {
    name: string;
    value: string;
    type: string;
    access: string;
  }[];
  onCancel: () => void;
  onAccept: (share) => void;
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Text variant="h5">
        Dette er de som nå vil få tilgang til prosjektet:
      </Text>

      <TableContainer
        style={{ height: 250, paddingTop: 50 }} // minus 25 to account for paper header shadow
        className={"common-scroll"}
      >
        <CustomScroll heightRelativeToParent="100%" minScrollHandleHeight={80}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Text variant="body1">Navn</Text>
                </TableCell>
                <TableCell>
                  <Text variant="body1">Tilgangstype (Firma/Bruker)</Text>
                </TableCell>
                <TableCell>
                  <Text variant="body1">Datatilgang</Text>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {completedSharingList.map((listElement) => {
                return (
                  <TableRow key={listElement.name}>
                    <TableCell>
                      <Text variant="body1">{listElement.name}</Text>
                    </TableCell>
                    <TableCell>
                      <Text variant="body1">
                        {listElement.type === "user" ? "Bruker" : "Firma"}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text variant="body1">
                        {listElement.access === "reader"
                          ? "Leser"
                          : listElement.access === "contributor"
                          ? "Tilbyder"
                          : "Ingen tilgang"}
                      </Text>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </CustomScroll>
      </TableContainer>
      <div className={classes.question}>
        <Text variant="h5">Er du sikker på at du vil dele prosjektet?</Text>
      </div>
      <div className={classes.buttonContainer}>
        <Button type="info" onClick={onCancel}>
          Nei, gå tilbake
        </Button>
        <Button
          type="info"
          onClick={() => {
            onAccept(completedSharingList);
          }}
        >
          Ja, del projeket
        </Button>
      </div>
    </div>
  );
}

export default ConfirmSharing;
