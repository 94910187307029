/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Delivery API
 * This API manages the projects and docuements that are stored in the Ameta Document Delivery. The API used oAuth2 flow for authorization.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Document,
    DocumentFromJSON,
    DocumentToJSON,
    NewDocument,
    NewDocumentFromJSON,
    NewDocumentToJSON,
    UpdateDocument,
    UpdateDocumentFromJSON,
    UpdateDocumentToJSON,
} from '../models';

export interface CreateDocumentRequest {
    projectId: string;
    newDocument: NewDocument;
}

export interface DeleteDocumentRequest {
    documentId: string;
}

export interface GetDocumentRequest {
    documentId: string;
}

export interface GetDownloadLinkRequest {
    documentId: string;
}

export interface UpdateDocumentRequest {
    documentId: string;
    updateDocument: UpdateDocument;
}

/**
 * 
 */
export class DocumentApi extends runtime.BaseAPI {

    /**
     * Create a new document for a project
     */
    async createDocumentRaw(requestParameters: CreateDocumentRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling createDocument.');
        }

        if (requestParameters.newDocument === null || requestParameters.newDocument === undefined) {
            throw new runtime.RequiredError('newDocument','Required parameter requestParameters.newDocument was null or undefined when calling createDocument.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/projects/{projectId}/documents`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewDocumentToJSON(requestParameters.newDocument),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create a new document for a project
     */
    async createDocument(requestParameters: CreateDocumentRequest): Promise<string> {
        const response = await this.createDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete a document
     */
    async deleteDocumentRaw(requestParameters: DeleteDocumentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling deleteDocument.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/documents/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete a document
     */
    async deleteDocument(requestParameters: DeleteDocumentRequest): Promise<void> {
        await this.deleteDocumentRaw(requestParameters);
    }

    /**
     * Get document element
     */
    async getDocumentRaw(requestParameters: GetDocumentRequest): Promise<runtime.ApiResponse<Document>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocument.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/documents/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentFromJSON(jsonValue));
    }

    /**
     * Get document element
     */
    async getDocument(requestParameters: GetDocumentRequest): Promise<Document> {
        const response = await this.getDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a download link for a document
     */
    async getDownloadLinkRaw(requestParameters: GetDownloadLinkRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDownloadLink.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/documents/{documentId}/download/`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get a download link for a document
     */
    async getDownloadLink(requestParameters: GetDownloadLinkRequest): Promise<string> {
        const response = await this.getDownloadLinkRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a document
     */
    async updateDocumentRaw(requestParameters: UpdateDocumentRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling updateDocument.');
        }

        if (requestParameters.updateDocument === null || requestParameters.updateDocument === undefined) {
            throw new runtime.RequiredError('updateDocument','Required parameter requestParameters.updateDocument was null or undefined when calling updateDocument.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/documents/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDocumentToJSON(requestParameters.updateDocument),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Update a document
     */
    async updateDocument(requestParameters: UpdateDocumentRequest): Promise<string> {
        const response = await this.updateDocumentRaw(requestParameters);
        return await response.value();
    }

}
