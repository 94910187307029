import React from "react";

import Box from "@mui/material/Box";

import PointcloudTable from "components/project/pointcloudsCard/PointcloudTable";
import Card from "@smartinspection/ameta-frontend-components/display/Card";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";

import { useQueryPointclouds } from "services/deliveryQueryHooks";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import ReloadButton from "@smartinspection/ameta-frontend-components/controls/ReloadButton";

export default function ProjectPointcloudsCard({
  projectId,
  disabled,
}: {
  projectId?: string;
  disabled?: boolean;
}) {
  const pointcloudQuery = useQueryPointclouds(projectId ? projectId : "");
  const pointclouds = pointcloudQuery.data;

  return (
    <Card
      type="drawer"
      title="Punktskyer"
      faIcon="cloud"
      disabled={disabled}
      rightHeaderComponent={
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            pr: 2,
          }}
        >
          <ReloadButton
            loading={pointcloudQuery.isFetching}
            onClick={() => {
              pointcloudQuery.refetch();
            }}
          />
        </Box>
      }
    >
      {!projectId ? (
        <CenterContainer>
          <Text variant="h6">Det finnes ingen punktskyer enda</Text>
        </CenterContainer>
      ) : pointcloudQuery.isLoading ? (
        <CircularProgress />
      ) : pointclouds ? (
        <PointcloudTable pointclouds={pointclouds} />
      ) : (
        <CenterContainer>
          <Text variant="h6">Det finnes ingen punktskyer enda</Text>
        </CenterContainer>
      )}
    </Card>
  );
}
