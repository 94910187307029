import React, { useState } from "react";

import Box from "@mui/material/Box";
import Dialog from "@smartinspection/ameta-frontend-components/layout/Dialog";

import SharingForm from "components/project/projectSharing/SharingForm";
import SharingComplete from "components/project/projectSharing/SharingComplete";
import ConfirmSharing from "components/project/projectSharing/ConfirmSharing";

import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";
import { authorizedProjectApi } from "services/apiService";

function ProjectSharing({
  projectId,
  open,
  onClose,
  previousCompletedList,
  edit,
  owner,
  refetch,
}: {
  projectId: string;
  open: boolean;
  onClose: () => void;
  previousCompletedList?: {
    type: "user" | "company";
    name: string;
    value: string;
    access: "reader" | "contributor";
  }[];
  edit?: boolean;
  owner?: boolean;
  refetch?: () => void;
}) {
  const [sharingList, setSharingList] = useState<
    {
      type: "user" | "company";
      name: string;
      value: string;
      access: "reader" | "contributor";
    }[]
  >(previousCompletedList ? previousCompletedList : []);
  const [completedSharingList, setCompletedSharingList] = useState<
    {
      type: "user" | "company";
      name: string;
      value: string;
      access: "reader" | "contributor";
    }[]
  >([]);

  const [requestLoading, setRequestLoading] = useState(false);
  const [requestComplete, setRequestComplete] = useState(false);
  const [requestError, setRequestError] = useState(false);
  return (
    <Dialog
      paperSx={{ minWidth: "50%", height: "90%" }}
      title={
        edit && owner
          ? "Endre tilgang"
          : edit && !owner
          ? "Hvem har tilgang?"
          : "Del prosjekt"
      }
      type="drawer"
      open={open}
      onClose={onClose}
    >
      <Box sx={{ height: "100%", width: "100%" }}>
        {completedSharingList.length <= 0 ? (
          <SharingForm
            projectId={projectId}
            owner={owner}
            edit={edit}
            sharingList={sharingList}
            onNext={() => {
              setCompletedSharingList(sharingList);
            }}
            onShareAdd={(element) => {
              setSharingList([...sharingList, element]);
            }}
            onShareEdit={(element) => {
              const newSharingList = [...sharingList];
              const index = newSharingList.findIndex(
                (el) => el.name === element.name
              );

              newSharingList[index] = element;
              setSharingList(newSharingList);
            }}
            onShareRemove={(element) => {
              if (edit) {
                setSharingList(sharingList);
              } else {
                setSharingList(
                  sharingList.filter((option) => {
                    return option !== element;
                  })
                );
              }
            }}
          />
        ) : requestLoading && !requestComplete ? (
          <CircularProgress />
        ) : requestComplete && !requestLoading ? (
          <SharingComplete
            message="Prosjektdelingen er fullført"
            onClose={() => {
              setRequestError(false);
              setRequestComplete(false);
              setSharingList([]);
              setCompletedSharingList([]);
              onClose();
            }}
          />
        ) : requestError && !requestLoading ? (
          <SharingComplete
            message="Kunne ikke dele prosjektet"
            onClose={() => {
              setRequestError(false);
              setRequestComplete(false);
              setSharingList([]);
              setCompletedSharingList([]);
              onClose();
            }}
          />
        ) : (
          <ConfirmSharing
            onCancel={() => {
              setSharingList(
                previousCompletedList ? previousCompletedList : []
              );
              setCompletedSharingList([]);
            }}
            onAccept={(completedSharingList) => {
              authorizedProjectApi().then((api) => {
                const requestInput = {};
                completedSharingList.forEach((element) => {
                  if (element.type === "user") {
                    if (!("userAccess" in requestInput)) {
                      requestInput["userAccess"] = [];
                    }
                    requestInput["userAccess"].push({
                      userEmail: element.value,
                      accessType: element.access,
                    });
                  }
                  if (element.type === "company") {
                    if (!("companyAccess" in requestInput)) {
                      requestInput["companyAccess"] = [];
                    }
                    requestInput["companyAccess"].push({
                      companyId: element.value,
                      accessType: element.access,
                    });
                  }
                });

                api
                  .shareProject({
                    projectId,
                    shareProject: requestInput,
                  })
                  .then(() => {
                    setRequestLoading(false);
                    setRequestComplete(true);
                  })
                  .then(refetch)
                  .catch(() => {
                    setRequestLoading(false);
                    setRequestError(true);
                  });
              });
            }}
            completedSharingList={completedSharingList}
          />
        )}
      </Box>
    </Dialog>
  );
}

export default ProjectSharing;
