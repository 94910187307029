/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Users API
 * This API manages the users that are stored in the Ameta platform. The API used oAuth2 flow for authorization.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserTenant,
    UserTenantFromJSON,
    UserTenantToJSON,
} from '../models';

export interface GetUserTenantRecordRequest {
    userId: string;
}

/**
 * 
 */
export class UserTenantApi extends runtime.BaseAPI {

    /**
     * Get a tenant records for user
     */
    async getUserTenantRecordRaw(requestParameters: GetUserTenantRecordRequest): Promise<runtime.ApiResponse<UserTenant>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserTenantRecord.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/users/{userId}/tenant`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserTenantFromJSON(jsonValue));
    }

    /**
     * Get a tenant records for user
     */
    async getUserTenantRecord(requestParameters: GetUserTenantRecordRequest): Promise<UserTenant> {
        const response = await this.getUserTenantRecordRaw(requestParameters);
        return await response.value();
    }

}
