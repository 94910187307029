import React from "react";

import ConfirmDialog from "@smartinspection/ameta-frontend-components/layout/ConfirmDialog";

import { authorizedDocumentApi } from "services/apiService";
import { ProjectDocumentRelation } from "services/deliveryApi/models";

function DocumentDeleteDialog({
  open,
  onClose,
  documentToDelete,
  refetchDocuments,
}: {
  open: boolean;
  onClose: () => void;
  documentToDelete: ProjectDocumentRelation;
  refetchDocuments: () => void;
}) {
  return (
    <ConfirmDialog
      open={open}
      cancelText="Avbryt"
      acceptText="Ja, slett dokumentet"
      onAccept={() => {
        authorizedDocumentApi().then((api) => {
          api.deleteDocument({ documentId: documentToDelete.sort }).then(() => {
            refetchDocuments();
          });
        });
        onClose();
      }}
      onCancel={onClose}
      text={`Er du sikker på du vil slette ${documentToDelete.documentName}?`}
    />
  );
}

export default DocumentDeleteDialog;
