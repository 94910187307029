import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Card from "@smartinspection/ameta-frontend-components/display/Card";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";
import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import TextField from "@smartinspection/ameta-frontend-components/input/TextField";
import Tooltip from "@smartinspection/ameta-frontend-components/display/Tooltip";
import ConfirmDialog from "@smartinspection/ameta-frontend-components/layout/ConfirmDialog";
import ProjectSharing from "components/project/projectSharing/ProjectSharing";
import DatePicker from "@smartinspection/ameta-frontend-components/pickers/DatePicker";
import { notify } from "@smartinspection/ameta-frontend-components/utils/notification";

import ShareIcons from "components/project/projectSharing/ShareIcons";

import { Project, NewProject } from "services/deliveryApi/models";

import { authorizedProjectApi } from "services/apiService";

interface InputForm {
  projectName: string;
  projectNumber: string;
  customerProjectNumber?: string;
  startDate?: string;
  endDate?: string;
}

export default function ProjectDetailsCard({
  editable,
  project,
}: {
  editable?: boolean;
  project?: Project;
}) {
  const [projectSharingOpen, setProjectSharingOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editEnabled, setEditEnabled] = useState(project ? false : true);
  const [formData, setFormData] = useState<InputForm>(
    project
      ? {
          projectName: project.projectName,
          projectNumber: project.projectNumber,
          customerProjectNumber: project.customerProjectNumber
            ? project.customerProjectNumber
            : undefined,
          startDate: project.startDate ? project.startDate : undefined,
          endDate: project.endDate ? project.endDate : undefined,
        }
      : { projectName: "", projectNumber: "" }
  );

  const navigate = useNavigate();

  const [vaildations, setValidations] = useState({
    projectName: true,
    projectNumber: true,
    customerProjectNumber: true,
  });

  const validateForm = (form) => {
    const currentValidation = { ...vaildations };

    if (!form.projectName) {
      currentValidation.projectName = false;
    }
    if (!form.projectNumber) {
      currentValidation.projectNumber = false;
    }
    if (!form.customerProjectNumber) {
      currentValidation.customerProjectNumber = false;
    }
    setValidations(currentValidation);
    return currentValidation.projectName && currentValidation.projectNumber;
  };

  const emptyFieldCheck = () => {
    return (
      !formData.projectName &&
      !formData.projectNumber &&
      !formData.customerProjectNumber &&
      !formData.endDate === null
    );
  };

  return (
    <React.Fragment>
      <Card
        type="drawer"
        title="Prosjektdetaljer"
        faIcon="file-alt"
        rightHeaderComponent={
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              pr: 2,
            }}
          >
            {project && <ShareIcons projectId={project.id} />}
            {editable && (
              <React.Fragment>
                <Tooltip
                  placement="top"
                  tooltipContent={
                    <Text variant="body1">
                      {!editEnabled
                        ? "Editer prosjektdetaljer"
                        : "Avslutt editering"}
                    </Text>
                  }
                >
                  <IconButton
                    noShadow={editEnabled}
                    colored={editEnabled}
                    sx={{ mr: 1 }}
                    type="info"
                    onClick={() => {
                      setEditEnabled((prev) => !prev);
                    }}
                  >
                    <FontAwesomeIcon icon="edit" />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  placement="top"
                  tooltipContent={<Text variant="body1">Del prosjekt</Text>}
                >
                  <IconButton
                    sx={{ mr: 1 }}
                    type="info"
                    onClick={() => {
                      setProjectSharingOpen(true);
                    }}
                  >
                    <FontAwesomeIcon icon="share-alt" />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  placement="top"
                  tooltipContent={<Text variant="body1">Slett prosjekt</Text>}
                >
                  <IconButton
                    type="error"
                    onClick={() => {
                      setDeleteDialogOpen(true);
                    }}
                  >
                    <FontAwesomeIcon icon="trash" />
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            )}
          </Box>
        }
      >
        <Box
          sx={{
            pt: 2,
            height: "100%",
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {project && (
            <TextField
              width={240}
              label="Prosjekteier"
              readOnly
              value={project ? project.projectOwnerName : ""}
            />
          )}
          <TextField
            width={240}
            label="Prosjektnavn"
            readOnly={!editEnabled}
            onChange={(value) => {
              setValidations({ ...vaildations, projectName: true });
              setFormData({
                ...formData,
                projectName: value.target.value,
              });
            }}
            value={formData.projectName}
            error={!vaildations.projectName}
          />

          <TextField
            width={240}
            label="Prosjektnr"
            readOnly={!editEnabled}
            onChange={(value) => {
              setValidations({ ...vaildations, projectNumber: true });
              setFormData({
                ...formData,
                projectNumber: value.target.value,
              });
            }}
            error={!vaildations.projectNumber}
            value={formData.projectNumber}
          />
          <TextField
            width={240}
            label="Prosjektnr, kunde"
            readOnly={!editEnabled}
            onChange={(value) => {
              setValidations({
                ...vaildations,
                customerProjectNumber: true,
              });
              setFormData({
                ...formData,
                customerProjectNumber: value.target.value,
              });
            }}
            error={!vaildations.customerProjectNumber}
            value={formData.customerProjectNumber}
          />

          <DatePicker
            width={240}
            label="Startdato"
            readOnly={!editEnabled}
            value={formData.startDate ? new Date(formData.startDate) : null}
            onChange={(date) => {
              setFormData({
                ...formData,
                startDate: date.toISOString(),
              });
            }}
          />

          <DatePicker
            width={240}
            label="Sluttdato"
            readOnly={!editEnabled}
            value={formData.endDate ? new Date(formData.endDate) : null}
            onChange={(date) => {
              setFormData({
                ...formData,
                endDate: date.toISOString(),
              });
            }}
          />
          {editEnabled && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {project ? (
                <Button
                  endIcon={<FontAwesomeIcon icon="save" />}
                  type="success"
                  onClick={() => {
                    if (!emptyFieldCheck()) {
                      authorizedProjectApi().then((api) => {
                        api
                          .updateProject({
                            projectId: String(project?.id),
                            updateProject: {
                              ...formData,
                            },
                          })
                          .then(() => {
                            notify({
                              message: "Prosjektet er oppdatert",
                              type: "success",
                            });
                          });
                      });
                      setEditEnabled(false);
                    }
                  }}
                >
                  Lagre
                </Button>
              ) : (
                <Button
                  endIcon={<FontAwesomeIcon icon="plus" />}
                  type="success"
                  onClick={() => {
                    if (!validateForm(formData)) {
                      notify({
                        message: "Ugyldig inputdata",
                        type: "warning",
                      });
                    } else {
                      authorizedProjectApi().then((api) => {
                        api
                          .createProject({
                            newProject: formData as NewProject,
                          })
                          .then((response) => {
                            console.log(response);
                            notify({
                              message: "Prosjektet ble opprettet",
                              type: "success",
                            });
                            navigate(
                              `/dashboard/project/${
                                JSON.parse(response).projectId
                              }`
                            );
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      });
                    }
                  }}
                >
                  Opprett prosjekt
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Card>
      {project && (
        <React.Fragment>
          <ProjectSharing
            projectId={project.id}
            open={projectSharingOpen}
            onClose={() => setProjectSharingOpen(false)}
          />
          <ConfirmDialog
            open={deleteDialogOpen}
            onCancel={() => {
              setDeleteDialogOpen(false);
            }}
            onAccept={() => {
              authorizedProjectApi()
                .then((api) => {
                  api.deleteProject({
                    projectId: project.id,
                  });
                })
                .then(() => {
                  notify({ message: "Projektet ble slettet", type: "success" });
                  navigate("/dashboard/list-view");
                });
            }}
            text="Er du sikker på at du vil slette dette prosjektet?"
            acceptText="Ja, slett dette prosjektet"
            cancelText="Nei, avbryt"
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
