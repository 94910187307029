import React from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CloseIcon from "@mui/icons-material/Close";

import Text from "@smartinspection/ameta-frontend-components/display/Text";
import Table from "@smartinspection/ameta-frontend-components/display/Table";

import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";

function ProjectSharingList({
  edit,
  listElements,
  onEdit,
  onRemove,
  owner,
}: {
  owner?: boolean;
  edit?: boolean;
  listElements: {
    type: "user" | "company";
    name: string;
    value: string;
    access: "reader" | "contributor";
  }[];
  onEdit: (element) => void;
  onRemove: (element) => void;
}) {
  const tableData = listElements.map((element) => {
    return {
      type: (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Text sx={{ pr: 1 }} variant="body1">
            {element.type === "company" ? "Firma" : "Bruker"}
          </Text>
          <FontAwesomeIcon
            icon={element.type === "company" ? "briefcase" : "user"}
          />
        </Box>
      ),
      name: element.name,
      access:
        owner === undefined || owner === true ? (
          <OptionsPicker
            width={200}
            label="Tilgang"
            onChange={(value) => {
              const newTempElement = { ...element, access: value };
              onEdit(newTempElement);
            }}
            optionsList={
              !edit
                ? {
                    reader: "Leser",
                    contributor: "Tilbyder",
                  }
                : {
                    reader: "Leser",
                    contributor: "Tilbyder",
                    noAccess: "Ingen tilgang",
                  }
            }
            value={element.access}
          />
        ) : (
          "-"
        ),
      delete: !edit ? (
        <IconButton
          noShadow
          type="info"
          onClick={() => {
            onRemove(element);
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : (
        "-"
      ),
    };
  });

  return (
    <Box sx={{ height: 500, width: "100%" }}>
      <Divider />
      {tableData.length > 0 && (
        <Table
          headers={[
            { fieldName: "type", name: "Tilgangstype", align: "center" },
            { fieldName: "name", name: "Navn", align: "center" },
            { fieldName: "access", name: "Tilgang", align: "center" },
            { fieldName: "delete", name: "Slett", align: "center" },
          ]}
          data={tableData}
        />
      )}

      <Divider />
    </Box>
  );
}

export default ProjectSharingList;
