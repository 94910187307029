import { authorizedDocumentApi } from "services/apiService";
import axios, { CancelTokenSource } from "axios";

// use this function as an onClick handler
export function downloadFile(documentId: string): void {
  authorizedDocumentApi().then((api) => {
    api.getDownloadLink({ documentId }).then((response) => {
      const element = document.createElement("a");
      element.href = JSON.parse(response).downloadLink;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    });
  });
}

export async function uploadFile(
  projectId: string,
  fileObject: { fileContent: File; cancelToken: CancelTokenSource },

  onUploadProgressCallback: (
    progressEvent: any // eslint-disable-line @typescript-eslint/no-explicit-any
    // disabled linting for progressEvent since the parameter is typed as "any"
    // in the axios library aswell.
  ) => void,
  onSuccess: () => void,
  onError?: (errorMessage: string) => void
) {
  authorizedDocumentApi().then((api) =>
    api
      .createDocument({
        projectId,
        newDocument: {
          documentName: fileObject.fileContent.name,
        },
      })
      .then((x) => {
        const documentExtension = fileObject.fileContent.name.split(".")[1];
        const data = JSON.parse(x);
        const url = data["url"];
        const fields = data["fields"];
        const form = new FormData();
        form.append("AWSAccessKeyId", fields.AWSAccessKeyId);
        form.append("key", fields.key);
        form.append("x-amz-security-token", fields["x-amz-security-token"]);
        form.append("policy", fields.policy);
        form.append("signature", fields.signature);
        form.append("Content-Disposition", fields["Content-Disposition"]);
        form.append("x-amz-meta-doctype", fields["x-amz-meta-doctype"]);
        if (
          documentExtension === "las" ||
          documentExtension === "laz" ||
          documentExtension === "e57"
        ) {
          form.append("Content-Type", fields["Content-Type"]);
        }
        form.append("file", fileObject.fileContent);

        axios
          .post(url, form, {
            cancelToken: fileObject.cancelToken.token,
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              onUploadProgressCallback(progress);
            },
          })
          .then(onSuccess)
          .catch((error) => {
            onError &&
              onError(error ? (error as string) : "ukjent feil ved opplasting");
          });
      })
  );
}
