import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import Text from "@smartinspection/ameta-frontend-components/display/Text";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      padding: 100,
    },
    button: { margin: 20 },
  })
);

function SharingComplete({
  message,
  onClose,
}: {
  message: string;
  onClose: () => void;
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Text variant="body1">{message}</Text>
      <Button type="info" onClick={onClose}>
        Lukk
      </Button>
    </div>
  );
}

export default SharingComplete;
