import React from "react";
import { useSelector } from "react-redux";
import { TStore } from "redux/store";
import TopView from "components/TopView";
import GridLayout from "@smartinspection/ameta-frontend-components/layout/GridLayout";

import Box from "@mui/material/Box";

import ProjectDetailsCard from "components/project/ProjectDetailsCard";
// import ProjectDescriptionCard from "components/project/descriptionCard/ProjectDescriptionCard";
import ProjectDocumentsCard from "components/project/documentsCard/ProjectDocumentsCard";
import ProjectLocationCard from "components/project/ProjectLocationCard";
import ProjectPointCloudsCard from "components/project/pointcloudsCard/ProjectPointcloudsCard";

export default function NewProject() {
  const globalState = useSelector((state: TStore) => state.global);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TopView title="Nytt prosjekt" />

      <GridLayout
        spacing={16}
        editable={globalState.layoutEditable}
        layout={[
          {
            i: "projectDetailsCard",
            h: 10,
            w: 6,
            x: 0,
            y: 0,
          },
          {
            i: "projectDocumentsCard",
            h: 20,
            w: 6,
            x: 6,
            y: 0,
          },
          {
            i: "projectLocationCard",
            h: 20,
            w: 6,
            x: 0,
            y: 10,
          },
          {
            i: "projectPointCloudsCard",
            h: 10,
            w: 6,
            x: 6,
            y: 20,
          },
        ]}
      >
        <ProjectDetailsCard key="projectDetailsCard" />

        <ProjectLocationCard
          key="projectLocationCard"
          disabled
          project={undefined}
        />

        <ProjectDocumentsCard
          key="projectDocumentsCard"
          disabled
          editable={false}
        />

        <ProjectPointCloudsCard key="projectPointCloudsCard" disabled />
      </GridLayout>
    </Box>
  );
}
