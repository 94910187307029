import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleHideMenus } from "redux/global";
import { TStore } from "redux/store";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";

import ametaLogo from "assets/img/Ameta-logo-hvit-flat_1.svg";

import SITopView from "@smartinspection/ameta-frontend-components/display/TopView";

import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";
import Text from "@smartinspection/ameta-frontend-components/display/Text";

function TopView({ title }: { title: string }) {
  const theme = useTheme();
  const globalState = useSelector((state: TStore) => state.global);
  const dispatch = useDispatch();
  return (
    <SITopView
      colorMode={theme.palette.mode}
      leftSideComponents={
        <Box
          sx={{
            height: "100%",
            display: "flex",

            alignItems: "center",
          }}
        >
          {globalState.hideMenus && (
            <Box
              sx={{
                height: "100%",
                width: 350,
                display: "flex",
                borderWidth: 3,
                justifyContent: "space-around",
                alignItems: "center",
                backgroundColor: theme.palette.ameta.background.dark,
              }}
            >
              <img
                style={{ height: "100%", paddingTop: 8, paddingBottom: 8 }}
                alt="Ameta logo"
                src={ametaLogo}
              />
              <IconButton
                type="success"
                onClick={() => {
                  dispatch(toggleHideMenus());
                  setTimeout(() => {
                    window.dispatchEvent(new Event("resize"));
                  }, 300);
                }}
              >
                <OpenInFullIcon />
              </IconButton>
            </Box>
          )}

          <Text sx={{ pl: 3 }} variant="h4">
            Delivery
          </Text>

          <Divider sx={{ mx: 2, height: 50 }} orientation="vertical" />

          <Text variant="h6">{title}</Text>
        </Box>
      }
    />
  );
}

export default TopView;
