import { Auth } from "aws-amplify";
import { QueryClient } from "react-query";
import {
  CompanyApi,
  DocumentApi,
  ProjectApi,
  UserCompanyApi,
} from "services/deliveryApi/apis";
import { UserApi } from "services/userApi/apis";
import { Configuration as DeliveryConfiguration } from "services/deliveryApi/runtime";
import { Configuration as UserConfiguration } from "services/userApi/runtime";
export const queryClient = new QueryClient();

export const authorizedProjectApi: () => Promise<ProjectApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new DeliveryConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + "/delivery",
  });

  return new ProjectApi(apiConfig);
};

export const authorizedDocumentApi: () => Promise<DocumentApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new DeliveryConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + "/delivery",
  });

  return new DocumentApi(apiConfig);
};

export const authorizedUserApi: () => Promise<UserApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new UserConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + "/users",
  });

  return new UserApi(apiConfig);
};

export const authorizedCompanyApi: () => Promise<CompanyApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new DeliveryConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + "/delivery",
  });
  return new CompanyApi(apiConfig);
};

export const authorizedUserCompanyApi: () => Promise<UserCompanyApi> =
  async () => {
    const currentUserSession = await Auth.currentSession();
    const idToken = currentUserSession.getIdToken().getJwtToken();
    const apiConfig = new DeliveryConfiguration({
      apiKey: idToken,
      basePath: process.env.REACT_APP_API_ENDPOINT + "/delivery",
    });
    return new UserCompanyApi(apiConfig);
  };
