import React, { useState } from "react";

import Box from "@mui/material/Box";

import Tooltip from "@smartinspection/ameta-frontend-components/display/Tooltip";
import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import Text from "@smartinspection/ameta-frontend-components/display/Text";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useQueryProjectCompaniesList,
  useQueryProjectUsersList,
  useQueryUserCompany,
} from "services/deliveryQueryHooks";

import { UserCompanyRelation } from "services/deliveryApi";
import ProjectSharing from "components/project/projectSharing/ProjectSharing";

function ShareIcons({
  projectId,
  owner,
}: {
  projectId: string;
  owner?: boolean;
}) {
  const projectCompanyListQuery = useQueryProjectCompaniesList(projectId);
  const projectUsersListQuery = useQueryProjectUsersList(projectId);

  const userCompanyQuery = useQueryUserCompany();
  const userCompany: UserCompanyRelation | undefined = userCompanyQuery.data;

  const projectCompanies = projectCompanyListQuery.data;
  const projectUsers = projectUsersListQuery.data;

  let numberOfUsers = 0;
  let numberOfCompanies = 0;
  const [companyProjectSharingOpen, setCompanyProjectSharingOpen] =
    useState(false);
  const [userProjectSharingOpen, setUserProjectSharingOpen] = useState(false);

  const companiesList: {
    type: "company";
    name: string;
    value: string;
    access: "reader" | "contributor";
  }[] = [];
  const usersList: {
    type: "user";
    name: string;
    value: string;
    access: "reader" | "contributor";
  }[] = [];

  projectCompanies &&
    userCompany &&
    projectCompanies
      .filter((company) => {
        return company.id !== userCompany.id;
      })
      .forEach((company) => {
        let access: "reader" | "contributor";
        if (company.accessType === "contributor") {
          access = "contributor";
        } else {
          access = "reader";
        }
        companiesList.push({
          type: "company",
          name: company.companyName,
          value: company.id,
          access: access,
        });
        numberOfCompanies += 1;
      });

  projectUsers &&
    userCompany &&
    projectUsers
      .filter((user) => {
        return user.id !== userCompany.id;
      })
      .forEach((user) => {
        let access: "reader" | "contributor";
        if (user.accessType === "contributor") {
          access = "contributor";
        } else {
          access = "reader";
        }
        usersList.push({
          type: "user",
          name: user.userName,
          value: user.id,
          access: access,
        });
        numberOfUsers += 1;
      });

  const ShareIcon = ({ type }: { type: "user" | "company" }) => {
    return (
      <Tooltip
        placement="top"
        tooltipContent={
          <Text variant="body1">
            {type === "company" ? "Firmatilganger" : "Brukertilganger"}
          </Text>
        }
      >
        <Button
          type="info"
          onClick={() => {
            if (type === "company") {
              setCompanyProjectSharingOpen(true);
            } else {
              setUserProjectSharingOpen(true);
            }
          }}
          endIcon={
            <FontAwesomeIcon icon={type === "company" ? "briefcase" : "user"} />
          }
        >
          {type === "company"
            ? String(numberOfCompanies)
            : String(numberOfUsers)}
        </Button>
      </Tooltip>
    );
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        pr: 3,
      }}
    >
      {numberOfUsers > 0 && <ShareIcon type="user" />}
      {numberOfUsers > 0 && numberOfCompanies > 0 && (
        <div style={{ width: 10 }} />
      )}
      {numberOfCompanies > 0 && <ShareIcon type="company" />}
      {numberOfCompanies > 0 && companyProjectSharingOpen && (
        <ProjectSharing
          projectId={projectId}
          open={companyProjectSharingOpen}
          onClose={() => setCompanyProjectSharingOpen(false)}
          previousCompletedList={companiesList}
          edit
          owner={owner}
          refetch={() => {
            projectCompanyListQuery.refetch();
          }}
        />
      )}
      {numberOfUsers > 0 && userProjectSharingOpen && (
        <ProjectSharing
          projectId={projectId}
          open={userProjectSharingOpen}
          onClose={() => setUserProjectSharingOpen(false)}
          previousCompletedList={usersList}
          edit
          owner={owner}
          refetch={() => {
            projectUsersListQuery.refetch();
          }}
        />
      )}
    </Box>
  );
}

export default ShareIcons;
