/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Delivery API
 * This API manages the projects and docuements that are stored in the Ameta Document Delivery. The API used oAuth2 flow for authorization.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ShareProjectCompanyAccess,
    ShareProjectCompanyAccessFromJSON,
    ShareProjectCompanyAccessFromJSONTyped,
    ShareProjectCompanyAccessToJSON,
    ShareProjectUserAccess,
    ShareProjectUserAccessFromJSON,
    ShareProjectUserAccessFromJSONTyped,
    ShareProjectUserAccessToJSON,
} from './';

/**
 * 
 * @export
 * @interface ShareProject
 */
export interface ShareProject {
    /**
     * 
     * @type {Array<ShareProjectUserAccess>}
     * @memberof ShareProject
     */
    userAccess?: Array<ShareProjectUserAccess>;
    /**
     * 
     * @type {Array<ShareProjectCompanyAccess>}
     * @memberof ShareProject
     */
    companyAccess?: Array<ShareProjectCompanyAccess>;
}

export function ShareProjectFromJSON(json: any): ShareProject {
    return ShareProjectFromJSONTyped(json, false);
}

export function ShareProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShareProject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userAccess': !exists(json, 'userAccess') ? undefined : ((json['userAccess'] as Array<any>).map(ShareProjectUserAccessFromJSON)),
        'companyAccess': !exists(json, 'companyAccess') ? undefined : ((json['companyAccess'] as Array<any>).map(ShareProjectCompanyAccessFromJSON)),
    };
}

export function ShareProjectToJSON(value?: ShareProject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userAccess': value.userAccess === undefined ? undefined : ((value.userAccess as Array<any>).map(ShareProjectUserAccessToJSON)),
        'companyAccess': value.companyAccess === undefined ? undefined : ((value.companyAccess as Array<any>).map(ShareProjectCompanyAccessToJSON)),
    };
}


