import React from "react";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getDocumentIcon from "utils/documentIconSelector";

import FileUploadItem from "components/project/documentsCard/FileUploadItem";

import { ProjectDocumentRelation } from "services/deliveryApi/models";

import DocumentMenu from "components/project/documentsCard/DocumentMenu";

import Table from "@smartinspection/ameta-frontend-components/display/Table";

const DocumentTable = ({
  projectId,
  editable,
  documents,
  filesToUpload,
  searchString,
  clearFile,
  refetchDocuments,
}: {
  projectId: string;
  editable: boolean;
  documents?: ProjectDocumentRelation[];
  filesToUpload: File[];
  searchString?: string;
  clearFile: (canceledFile: File) => void;
  refetchDocuments: () => void;
}) => {
  const fileUploadTableData: {
    fileIcon: React.ReactNode;
    name: string;
    createdAt: React.ReactNode;
  }[] = filesToUpload.map((file) => {
    const iconProps = getDocumentIcon(file.name);
    return {
      fileIcon: (
        <FontAwesomeIcon
          style={{ color: iconProps.color }}
          size="2x"
          icon={iconProps.icon}
        />
      ),
      name: file.name,
      createdAt: (
        <FileUploadItem
          key={file.name}
          projectId={projectId}
          file={file}
          clearFile={clearFile}
        />
      ),
    };
  });

  const documentsTableData: {
    fileIcon: React.ReactNode;
    name: string;
    createdAt: string;
    timestamp: number;
    options: React.ReactNode;
  }[] = [];

  documents &&
    documents.forEach((document) => {
      const iconProps = getDocumentIcon(document.documentName);

      documentsTableData.push({
        fileIcon: (
          <FontAwesomeIcon
            style={{ color: iconProps.color }}
            size="2x"
            icon={iconProps.icon}
          />
        ),
        name: document.documentName,
        createdAt: format(
          new Date(document.documentTimeCreated),
          "dd.MM.yyyy HH:mm"
        ),
        timestamp: new Date(document.documentTimeCreated).getTime(),
        options: (
          <DocumentMenu
            document={document}
            hideDelete={!editable}
            refetchDocuments={refetchDocuments}
          />
        ),
      });
    });

  documentsTableData.sort((a, b) => {
    return a.timestamp - b.timestamp;
  });

  return (
    <Table
      headers={[
        { fieldName: "fileIcon", name: "" },
        { fieldName: "name", name: "Filnavn" },
        {
          fieldName: "createdAt",
          name: filesToUpload.length > 0 ? "Opprettet/Status" : "Opprettet",
        },
        { fieldName: "options", name: "", align: "center" },
      ]}
      data={[...fileUploadTableData, ...documentsTableData]}
    />
  );
};

export default React.memo(DocumentTable, (prevProps, nextProps) => {
  // if there are uploads in progress, don't update
  // table before all uploads are complete
  if (prevProps.filesToUpload.length > 0) {
    if (nextProps.filesToUpload.length === 0) {
      return false;
    } else {
      return true;
    }

    // if there are no uploads in progress, update on
    // a document or file change
  } else if (
    prevProps.documents !== nextProps.documents ||
    prevProps.filesToUpload !== nextProps.filesToUpload
  ) {
    return false;
  } else {
    return true;
  }
});
