import React, { useRef, useState } from "react";

import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";

import Text from "@smartinspection/ameta-frontend-components/display/Text";
import Card from "@smartinspection/ameta-frontend-components/display/Card";
import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";
import TextField from "@smartinspection/ameta-frontend-components/input/TextField";
import UploadButton from "@smartinspection/ameta-frontend-components/controls/UploadButton";
import ReloadButton from "@smartinspection/ameta-frontend-components/controls/ReloadButton";

import { useQueryDocuments } from "services/deliveryQueryHooks";

import DocumentTable from "components/project/documentsCard/DocumentTable";
import Dropzone from "components/common/Dropzone";
import DragAndDropIndicator from "components/common/DragAndDropIndicator";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";

export default function ProjectDocumentsCard({
  projectId,
  disabled,
  editable,
}: {
  projectId?: string;
  disabled?: boolean;
  editable: boolean;
}) {
  const documentsQuery = useQueryDocuments(projectId ? projectId : "");
  const documents = documentsQuery.data;

  const [documentSearchString, setDocumentSearchString] = useState("");

  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);

  const filesToUploadRef = useRef(filesToUpload); // needed to get updated state for clear file function, since its called from a setTimeout
  filesToUploadRef.current = filesToUpload;

  const clearFile = (fileToClear: File) => {
    const newFileArray = filesToUploadRef.current.filter(function (file) {
      return file.name !== fileToClear.name;
    });
    setFilesToUpload(newFileArray);

    if (newFileArray.length === 0) {
      documentsQuery.refetch();
    }
  };

  let bodyContent: React.ReactNode = null;

  if (!projectId) {
    bodyContent = (
      <CenterContainer>
        <Text variant="body1">Det finnes ingen dokumenter enda</Text>
      </CenterContainer>
    );
  } else if (documentsQuery.isLoading) {
    bodyContent = <CircularProgress />;
  } else if (editable) {
    bodyContent = (
      <Dropzone onDrop={setFilesToUpload}>
        {documents || filesToUpload.length !== 0 ? (
          <DocumentTable
            projectId={projectId}
            editable={editable}
            documents={documents}
            filesToUpload={filesToUpload}
            clearFile={clearFile}
            refetchDocuments={() => {
              documentsQuery.refetch();
            }}
          />
        ) : (
          <DragAndDropIndicator text="Dra og slipp dine første filer her" />
        )}
      </Dropzone>
    );
  } else {
    bodyContent = documents ? (
      <DocumentTable
        projectId={projectId}
        editable={editable}
        documents={documents}
        filesToUpload={filesToUpload}
        clearFile={clearFile}
        refetchDocuments={() => {
          documentsQuery.refetch();
        }}
      />
    ) : (
      <CenterContainer>
        <Text variant="body1">Det finnes ingen dokumenter enda</Text>
      </CenterContainer>
    );
  }

  return (
    <Card
      type="drawer"
      title="Dokumenter"
      faIcon="file"
      disabled={disabled}
      rightHeaderComponent={
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pr: 2,
          }}
        >
          {editable && (
            <Box sx={{ px: 2 }}>
              <UploadButton onFilesSelected={setFilesToUpload} />
            </Box>
          )}
          <Box sx={{ pr: 1 }}>
            {filesToUpload.length === 0 && (
              <ReloadButton
                loading={documentsQuery.isFetching}
                onClick={() => {
                  documentsQuery.refetch();
                }}
              />
            )}
          </Box>

          <TextField
            InputProps={{
              endAdornment: <SearchIcon />,
              disableUnderline: true,
            }}
            onChange={(e) => {
              setDocumentSearchString(e.target.value.toLowerCase());
            }}
            label="Søk etter dokument"
            value={documentSearchString}
          />
        </Box>
      }
    >
      {bodyContent}
    </Card>
  );
}
