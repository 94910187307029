/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Delivery API
 * This API manages the projects and docuements that are stored in the Ameta Document Delivery. The API used oAuth2 flow for authorization.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeoJSONLocation,
    GeoJSONLocationFromJSON,
    GeoJSONLocationFromJSONTyped,
    GeoJSONLocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    documentName: string;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    documentSize: number;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    documentS3Href: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    documentType: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    documentTimeCreated: string;
    /**
     * 
     * @type {GeoJSONLocation}
     * @memberof Document
     */
    documentLocation?: GeoJSONLocation;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    documentStatus?: string;
}

export function DocumentFromJSON(json: any): Document {
    return DocumentFromJSONTyped(json, false);
}

export function DocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Document {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'documentName': json['documentName'],
        'documentSize': json['documentSize'],
        'documentS3Href': json['documentS3Href'],
        'documentType': json['documentType'],
        'documentTimeCreated': json['documentTimeCreated'],
        'documentLocation': !exists(json, 'documentLocation') ? undefined : GeoJSONLocationFromJSON(json['documentLocation']),
        'documentStatus': !exists(json, 'documentStatus') ? undefined : json['documentStatus'],
    };
}

export function DocumentToJSON(value?: Document | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'documentName': value.documentName,
        'documentSize': value.documentSize,
        'documentS3Href': value.documentS3Href,
        'documentType': value.documentType,
        'documentTimeCreated': value.documentTimeCreated,
        'documentLocation': GeoJSONLocationToJSON(value.documentLocation),
        'documentStatus': value.documentStatus,
    };
}


