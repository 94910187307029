import React, { useState } from "react";

import {
  PopperMenu,
  IconButton,
} from "@smartinspection/ameta-frontend-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DocumentDeleteDialog from "components/project/documentsCard/DocumentDeleteDialog";

import { ProjectDocumentRelation } from "services/deliveryApi/models";
import { downloadFile } from "services/fileService";

function DocumentMenu({
  document,
  hideDelete,
  refetchDocuments,
}: {
  document: ProjectDocumentRelation;
  hideDelete?: boolean;
  refetchDocuments: () => void;
}) {
  const [deleteDialogOpen, setDeleteDialigOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const menuItems = [
    {
      label: "Last ned",
      onClick: () => {
        downloadFile(document.sort);
      },
    },
  ];

  if (!hideDelete) {
    menuItems.push({
      label: "Slett",
      onClick: () => {
        setDeleteDialigOpen(true);
      },
    });
  }

  return (
    <React.Fragment>
      <DocumentDeleteDialog
        documentToDelete={document}
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialigOpen(false);
        }}
        refetchDocuments={refetchDocuments}
      />

      <PopperMenu
        anchorEl={anchorEl}
        onClickAway={() => {
          setAnchorEl(null);
        }}
        menuItems={menuItems}
      />

      <IconButton
        noShadow
        type="info"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <FontAwesomeIcon icon="bars" />
      </IconButton>
    </React.Fragment>
  );
}

export default DocumentMenu;
