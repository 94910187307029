import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCalculator,
  faLocationCrosshairs,
  faListAlt,
  faFile,
  faFileAlt,
  faEdit,
  faTrash,
  faShareAlt,
  faSave,
  faPlus,
  faBriefcase,
  faUser,
  faBars,
  faCloud,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faCalculator,
  faLocationCrosshairs,
  faListAlt,
  faFile,
  faFileAlt,
  faEdit,
  faTrash,
  faShareAlt,
  faSave,
  faPlus,
  faBriefcase,
  faUser,
  faBars,
  faCloud
);
