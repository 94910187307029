/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Delivery API
 * This API manages the projects and docuements that are stored in the Ameta Document Delivery. The API used oAuth2 flow for authorization.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeoJSONLocation,
    GeoJSONLocationFromJSON,
    GeoJSONLocationFromJSONTyped,
    GeoJSONLocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserProjectRelation
 */
export interface UserProjectRelation {
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelation
     */
    sort: string;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelation
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelation
     */
    accessType: string;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelation
     */
    projectName: string;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelation
     */
    projectNumber: string;
    /**
     * 
     * @type {GeoJSONLocation}
     * @memberof UserProjectRelation
     */
    projectLocation?: GeoJSONLocation;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelation
     */
    projectDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelation
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelation
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelation
     */
    customerProjectNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelation
     */
    projectOwnerName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelation
     */
    projectOwnerId?: string;
}

export function UserProjectRelationFromJSON(json: any): UserProjectRelation {
    return UserProjectRelationFromJSONTyped(json, false);
}

export function UserProjectRelationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProjectRelation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sort': json['sort'],
        'userName': json['userName'],
        'accessType': json['accessType'],
        'projectName': json['projectName'],
        'projectNumber': json['projectNumber'],
        'projectLocation': !exists(json, 'projectLocation') ? undefined : GeoJSONLocationFromJSON(json['projectLocation']),
        'projectDescription': !exists(json, 'projectDescription') ? undefined : json['projectDescription'],
        'startDate': !exists(json, 'startDate') ? undefined : json['startDate'],
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
        'customerProjectNumber': !exists(json, 'customerProjectNumber') ? undefined : json['customerProjectNumber'],
        'projectOwnerName': !exists(json, 'projectOwnerName') ? undefined : json['projectOwnerName'],
        'projectOwnerId': !exists(json, 'projectOwnerId') ? undefined : json['projectOwnerId'],
    };
}

export function UserProjectRelationToJSON(value?: UserProjectRelation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sort': value.sort,
        'userName': value.userName,
        'accessType': value.accessType,
        'projectName': value.projectName,
        'projectNumber': value.projectNumber,
        'projectLocation': GeoJSONLocationToJSON(value.projectLocation),
        'projectDescription': value.projectDescription,
        'startDate': value.startDate,
        'endDate': value.endDate,
        'customerProjectNumber': value.customerProjectNumber,
        'projectOwnerName': value.projectOwnerName,
        'projectOwnerId': value.projectOwnerId,
    };
}


