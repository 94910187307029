import React, { useState } from "react";

import Box from "@mui/material/Box";

import Text from "@smartinspection/ameta-frontend-components/display/Text";
import TextField from "@smartinspection/ameta-frontend-components/input/TextField";
import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";

import ProjectSharingList from "components/project/projectSharing/ProjectSharingList";
import isEmail from "utils/validation/isEmail";
import isEmpty from "utils/validation/isEmpty";

import {
  useQueryAllCompanies,
  useQueryProjectCompaniesList,
} from "services/deliveryQueryHooks";

function SharingForm({
  projectId,
  sharingList,
  onNext,
  onShareAdd,
  onShareEdit,
  onShareRemove,
  edit,
  owner,
}: {
  projectId: string;
  sharingList: {
    type: "user" | "company";
    name: string;
    value: string;
    access: "reader" | "contributor";
  }[];
  onNext: () => void;
  onShareAdd: (element) => void;
  onShareEdit: (element) => void;
  onShareRemove: (element) => void;
  edit?: boolean;
  owner?: boolean;
}) {
  const companiesWithAccessQuery = useQueryProjectCompaniesList(projectId);
  const companiesWithAccess = companiesWithAccessQuery.data;

  const allCompaniesQuery = useQueryAllCompanies();
  const allCompanies = allCompaniesQuery.data;

  const [newUser, setNewUser] = useState<string | undefined>(undefined);
  const [newCompany, setNewCompany] = useState<{
    name: string;
    value: string;
  } | null>(null);
  const [companyOptions, setCompanyOptions] = useState<{
    [key: string]: string;
  }>({});
  const [optionsFetched, setOptionsFetched] = useState(false);

  const [emailError, setEmailError] = useState(false);

  if (!optionsFetched) {
    if (allCompanies && companiesWithAccess) {
      let temporaryOptionsStore = allCompanies.map((company) => {
        return {
          name: company.companyName,
          value: company.id,
        };
      });

      temporaryOptionsStore.forEach((option) => {
        companiesWithAccess.forEach((companyWithAccess) => {
          if (option.value === companyWithAccess.id) {
            temporaryOptionsStore = temporaryOptionsStore.filter((option) => {
              return option.value !== companyWithAccess.id;
            });
          }
        });
        setCompanyOptions((prev) => {
          return { ...prev, [option.value]: option.name };
        });
      });

      setOptionsFetched(true);
    }
  }

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        px: 4,
      }}
    >
      {!edit && (
        <Box
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ py: 2 }}>
            <Text variant="body1">Legg til ny bruker :</Text>
            <Box sx={{ py: 1 }}>
              <TextField
                width={280}
                label={
                  emailError ? "Epost mangler eller ugyldig" : "Epostadresse"
                }
                type="email"
                error={emailError}
                value={newUser}
                onChange={(event) => {
                  if (emailError) {
                    setEmailError(false);
                  }

                  setNewUser(event.target.value);
                }}
              />
            </Box>

            <Button
              type="success"
              onClick={() => {
                if (newUser) {
                  setEmailError(isEmpty(newUser) || !isEmail(newUser));
                  if (!isEmpty(newUser) && isEmail(newUser)) {
                    onShareAdd({
                      type: "user",
                      name: newUser,
                      value: newUser,
                      access: "reader",
                    });
                    setNewUser("");
                  }
                }
              }}
            >
              Legg til
            </Button>
          </Box>
          <Box sx={{ py: 2 }}>
            <Text variant="body1">Legg til nytt firma :</Text>

            <Box sx={{ py: 1 }}>
              <OptionsPicker
                width={280}
                label="Firmanavn"
                value={newCompany && newCompany.value ? newCompany.value : ""}
                optionsList={companyOptions}
                onChange={(value) => {
                  if (value) {
                    setNewCompany({ name: companyOptions[value], value });
                  }
                }}
              />
            </Box>

            <Button
              type="success"
              onClick={() => {
                if (newCompany) {
                  onShareAdd({
                    type: "company",
                    name: newCompany.name,
                    value: newCompany.value,
                    access: "reader",
                  });
                  setCompanyOptions((prev) => {
                    const temp = { ...prev };

                    delete temp[newCompany.value];

                    return temp;
                  });
                  setNewCompany(null);
                }
              }}
            >
              Legg til
            </Button>
          </Box>
        </Box>
      )}

      <ProjectSharingList
        owner={owner}
        edit={edit}
        onEdit={(element) => {
          onShareEdit(element);
        }}
        onRemove={(element) => {
          onShareRemove(element);
          if (element.type === "company") {
            setCompanyOptions((prev) => {
              return { ...prev, [element.value]: element.name };
            });
          }
        }}
        listElements={sharingList}
      />

      <Box sx={{ display: "flex", justifyContent: "flex-end", p: 4 }}>
        {(sharingList.length > 0 || edit) &&
          (owner === undefined || owner === true) && (
            <Button
              type="success"
              onClick={() => {
                onNext();
              }}
            >
              Gå videre
            </Button>
          )}
      </Box>
    </Box>
  );
}

export default SharingForm;
