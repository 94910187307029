import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";

import NavBar from "components/NavBar";

import Map from "pages/Map";
import List from "pages/ListView";
import Project from "pages/Project";
import NewProject from "pages/NewProject";
import Settings from "pages/Settings";

import isUserLoggedIn from "utils/isUserLoggedIn";

export default function Main() {
  const user = isUserLoggedIn();
  const navigate = useNavigate();
  useEffect(() => {
    if (user === "nouser") {
      navigate("/");
    }
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }}>
      <NavBar />

      <Routes>
        <Route path="/" element={<Navigate replace to="map" />} />
        <Route path="list-view" element={<List />} />
        <Route path="map" element={<Map />} />
        <Route path="project/:projectId" element={<Project />} />
        <Route path="new-project" element={<NewProject />} />
        <Route path="settings" element={<Settings />} />
      </Routes>
    </Box>
  );
}
