// checks if an object is empty
const isEmpty: (
  value: undefined | null | Record<string, unknown> | string
) => boolean = (value: undefined | null | Record<string, unknown> | string) =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

export default isEmpty;
