/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Delivery API
 * This API manages the projects and docuements that are stored in the Ameta Document Delivery. The API used oAuth2 flow for authorization.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    applicationAccess: string;
}

export function CompanyFromJSON(json: any): Company {
    return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Company {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyName': json['companyName'],
        'applicationAccess': json['applicationAccess'],
    };
}

export function CompanyToJSON(value?: Company | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'companyName': value.companyName,
        'applicationAccess': value.applicationAccess,
    };
}


