import { configureStore } from "@reduxjs/toolkit";

import globalReducer from "redux/global";

const store = configureStore({
  reducer: { global: globalReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
export default store;
export type TStore = ReturnType<typeof store.getState>;
