import React from "react";
import { useSelector } from "react-redux";
import { TStore } from "redux/store";

import Amplify from "aws-amplify";
import ThemeProvider from "@smartinspection/ameta-frontend-components/style/ThemeProvider";
import { NotificationContainer } from "@smartinspection/ameta-frontend-components/utils/notification";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClientProvider } from "react-query";

import { Provider } from "react-redux";
import store from "redux/store";

import Main from "Main";
import FrontPage from "pages/FrontPage";

import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "services/apiService";
import config from "config/auth-config";

import SessionIdCookie from "services/SessionIdCookie";

import "faIcons";

Amplify.configure(config);
const AppContent = () => {
  const globalState = useSelector((state: TStore) => state.global);
  return (
    <ThemeProvider colorMode={globalState.colorMode}>
      <Routes>
        <Route path="/" element={<FrontPage />} />
        <Route path="/dashboard/*" element={<Main />} />
      </Routes>
    </ThemeProvider>
  );
};

function App() {
  return (
    <div style={{ height: "100vh" }}>
      <BrowserRouter>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <SessionIdCookie />
            <AppContent />
            <ReactQueryDevtools />
          </QueryClientProvider>
        </Provider>
        <NotificationContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
