import React from "react";
import { format } from "date-fns";

import { useNavigate } from "react-router-dom";

import Table from "@smartinspection/ameta-frontend-components/display/Table";

import { CompanyProjectRelation } from "services/deliveryApi/models";

export default function ProjectTable({
  projects,
}: {
  projects: CompanyProjectRelation[] | undefined;
}) {
  const navigate = useNavigate();

  return (
    <Table
      onRowClick={(data) => {
        navigate(`/dashboard/project/${data.sort}`);
      }}
      headers={[
        {
          fieldName: "companyName",
          name: "Eier",
        },
        {
          fieldName: "projectName",
          name: "Prosjektnavn",
        },
        {
          fieldName: "projectNumber",
          name: "Prosjektnummer",
        },
        {
          fieldName: "projectDescription",
          name: "Om prosjektet",
        },
        {
          fieldName: "period",
          align: "left",
          name: "Tidsperiode",
        },
      ]}
      data={
        projects &&
        projects.map((project) => {
          return {
            ...project,
            period: project.startDate
              ? `${format(new Date(project.startDate), "dd.MM.yyyy")} - ${
                  project.endDate
                    ? format(new Date(project.endDate), "dd.MM.yyyy")
                    : "nå"
                }`
              : "-",
          };
        })
      }
    />
  );
}
