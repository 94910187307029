import React from "react";

import Box from "@mui/material/Box";

import TopView from "components/TopView";
import MapView from "components/map/MapView";
import { useQueryProjects } from "services/deliveryQueryHooks";

export default function Map() {
  const projectQuery = useQueryProjects();
  const projects = projectQuery.data;

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TopView title="Kartvisning" />
      <MapView projects={projects} />
    </Box>
  );
}
