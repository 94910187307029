/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Delivery API
 * This API manages the projects and docuements that are stored in the Ameta Document Delivery. The API used oAuth2 flow for authorization.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeoJSONLocation,
    GeoJSONLocationFromJSON,
    GeoJSONLocationFromJSONTyped,
    GeoJSONLocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectPointcloudRelation
 */
export interface ProjectPointcloudRelation {
    /**
     * 
     * @type {string}
     * @memberof ProjectPointcloudRelation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPointcloudRelation
     */
    sort: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPointcloudRelation
     */
    projectName: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPointcloudRelation
     */
    projectNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPointcloudRelation
     */
    pointcloudName: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPointcloudRelation
     */
    pointcloudS3Href: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPointcloudRelation
     */
    processStatus: string;
    /**
     * 
     * @type {GeoJSONLocation}
     * @memberof ProjectPointcloudRelation
     */
    pointcloudLocation?: GeoJSONLocation;
}

export function ProjectPointcloudRelationFromJSON(json: any): ProjectPointcloudRelation {
    return ProjectPointcloudRelationFromJSONTyped(json, false);
}

export function ProjectPointcloudRelationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectPointcloudRelation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sort': json['sort'],
        'projectName': json['projectName'],
        'projectNumber': json['projectNumber'],
        'pointcloudName': json['pointcloudName'],
        'pointcloudS3Href': json['pointcloudS3Href'],
        'processStatus': json['processStatus'],
        'pointcloudLocation': !exists(json, 'pointcloudLocation') ? undefined : GeoJSONLocationFromJSON(json['pointcloudLocation']),
    };
}

export function ProjectPointcloudRelationToJSON(value?: ProjectPointcloudRelation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sort': value.sort,
        'projectName': value.projectName,
        'projectNumber': value.projectNumber,
        'pointcloudName': value.pointcloudName,
        'pointcloudS3Href': value.pointcloudS3Href,
        'processStatus': value.processStatus,
        'pointcloudLocation': GeoJSONLocationToJSON(value.pointcloudLocation),
    };
}


