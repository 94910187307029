import React from "react";

import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import Text from "@smartinspection/ameta-frontend-components/display/Text";

import Map from "@smartinspection/ameta-frontend-components/map/Map";
import MarkerCluster from "@smartinspection/ameta-frontend-components/map/MarkerCluster";
import GeoJson from "@smartinspection/ameta-frontend-components/map/GeoJson";

import { CompanyProjectRelation } from "services/deliveryApi/models/CompanyProjectRelation";

import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";

export default function MapView({
  projects,
}: {
  projects: CompanyProjectRelation[] | undefined;
}) {
  const theme = useTheme();

  const navigate = useNavigate();

  return (
    <Map zoom={8}>
      {projects && (
        <MarkerCluster clusterColor={theme.palette.info.main}>
          {projects.map((project) => {
            return (
              <GeoJson
                marker={{
                  color: "blue",
                  icon: "location-crosshairs",
                }}
                onClick={() => {
                  navigate(`/dashboard/project/${project.sort}`);
                }}
                data={project.projectLocation}
                tooltip={
                  <Box
                    sx={{
                      p: 1,
                    }}
                  >
                    <Text sx={{ py: 1 }} variant="h6">
                      {project.companyName}
                    </Text>
                    <Divider orientation="horizontal" />
                    <Box sx={{ py: 1 }}>
                      <Text variant="body1">{project.projectName}</Text>
                      <Text variant="body1">{project.projectNumber}</Text>
                    </Box>
                  </Box>
                }
              />
            );
          })}
        </MarkerCluster>
      )}
    </Map>
  );
}
