/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Delivery API
 * This API manages the projects and docuements that are stored in the Ameta Document Delivery. The API used oAuth2 flow for authorization.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeoJSONLocationGeometry,
    GeoJSONLocationGeometryFromJSON,
    GeoJSONLocationGeometryFromJSONTyped,
    GeoJSONLocationGeometryToJSON,
} from './';

/**
 * 
 * @export
 * @interface GeoJSONLocation
 */
export interface GeoJSONLocation {
    /**
     * 
     * @type {string}
     * @memberof GeoJSONLocation
     */
    type: string;
    /**
     * 
     * @type {GeoJSONLocationGeometry}
     * @memberof GeoJSONLocation
     */
    geometry: GeoJSONLocationGeometry;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof GeoJSONLocation
     */
    properties: { [key: string]: object; };
}

export function GeoJSONLocationFromJSON(json: any): GeoJSONLocation {
    return GeoJSONLocationFromJSONTyped(json, false);
}

export function GeoJSONLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeoJSONLocation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'geometry': GeoJSONLocationGeometryFromJSON(json['geometry']),
        'properties': json['properties'],
    };
}

export function GeoJSONLocationToJSON(value?: GeoJSONLocation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'geometry': GeoJSONLocationGeometryToJSON(value.geometry),
        'properties': value.properties,
    };
}


