/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Delivery API
 * This API manages the projects and docuements that are stored in the Ameta Document Delivery. The API used oAuth2 flow for authorization.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserCompanyRelation
 */
export interface UserCompanyRelation {
    /**
     * 
     * @type {string}
     * @memberof UserCompanyRelation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserCompanyRelation
     */
    sort: string;
    /**
     * 
     * @type {string}
     * @memberof UserCompanyRelation
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof UserCompanyRelation
     */
    companyName: string;
}

export function UserCompanyRelationFromJSON(json: any): UserCompanyRelation {
    return UserCompanyRelationFromJSONTyped(json, false);
}

export function UserCompanyRelationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCompanyRelation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sort': json['sort'],
        'userName': json['userName'],
        'companyName': json['companyName'],
    };
}

export function UserCompanyRelationToJSON(value?: UserCompanyRelation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sort': value.sort,
        'userName': value.userName,
        'companyName': value.companyName,
    };
}


