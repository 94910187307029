import React, { useState } from "react";

import { useSelector } from "react-redux";
import { TStore } from "redux/store";

import Box from "@mui/material/Box";

import TopView from "components/TopView";
import ProjectTable from "components/listView/ProjectTable";

import Card from "@smartinspection/ameta-frontend-components/display/Card";
import GridLayout from "@smartinspection/ameta-frontend-components/layout/GridLayout";
import TextField from "@smartinspection/ameta-frontend-components/input/TextField";
import { useQueryProjects } from "services/deliveryQueryHooks";

import SearchIcon from "@mui/icons-material/Search";

export default function ListView() {
  const globalState = useSelector((state: TStore) => state.global);
  const projectQuery = useQueryProjects();
  const projects = projectQuery.data;

  const [searchString, setSearchString] = useState("");

  const filteredProjects =
    projects && searchString.length > 0
      ? projects.filter((project) => {
          return (
            project.projectName.toLowerCase().includes(searchString) ||
            (project.companyName &&
              project.companyName.toLowerCase().includes(searchString))
          );
        })
      : projects;

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <TopView title="Listeoversikt" />
      <GridLayout
        spacing={16}
        editable={globalState.layoutEditable}
        layout={[
          {
            i: "listView",
            h: 30,
            w: 12,
            x: 0,
            y: 0,
          },
        ]}
      >
        <Card
          title="Prosjektoversikt"
          key="listView"
          type="drawer"
          faIcon="list-alt"
          rightHeaderComponent={
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <TextField
                InputProps={{
                  endAdornment: <SearchIcon />,
                  disableUnderline: true,
                }}
                onChange={(e) => {
                  setSearchString(e.target.value.toLowerCase());
                }}
                label="Søk etter prosjekt"
                value={searchString}
              />
            </Box>
          }
        >
          <ProjectTable key="projectTable" projects={filteredProjects} />
        </Card>
      </GridLayout>
    </Box>
  );
}
