import React, { useState, useEffect } from "react";

import axios from "axios";

import Box from "@mui/material/Box";

import { styled } from "@mui/material/styles";

import LinearProgress from "@mui/material/LinearProgress";

import Text from "@smartinspection/ameta-frontend-components/display/Text";

import { uploadFile } from "services/fileService";

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 25,
  width: "100%",
  backgroundColor: theme.palette.grey[400],
  "& .MuiLinearProgress-bar": {
    height: 10,
    borderRadius: 25,
    background: "linear-gradient(270deg, #11E5B3 0%, #3C4F87 180%);",
  },
}));

export function LinearProgressWithLabel({ value }: { value: number }) {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <StyledLinearProgress variant="determinate" value={value} />
      <Text sx={{ pl: 2 }} variant="body1">{`${Math.round(value)}%`}</Text>
    </Box>
  );
}

function FileUploadItem({
  projectId,
  file,
  clearFile,
}: {
  projectId: string;
  file: File;
  clearFile: (canceledFile: File) => void;
}) {
  const [cancelToken] = useState(axios.CancelToken.source());
  const [uploadProgress, setUploadProgress] = useState(0);

  const [uploadStarted, setUploadStarted] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);

  useEffect(() => {
    if (!uploadStarted) {
      setUploadStarted(true);
      uploadFile(
        projectId,
        {
          fileContent: file,
          cancelToken: cancelToken,
        },
        (progress) => {
          setUploadProgress(progress);
        },
        () => {
          setUploadComplete(true);
          // waiting two seconds before refeching files from sever and removing
          setTimeout(() => {
            clearFile(file);
          }, 2500);
        },
        () => {
          setUploadFailed(true);
        }
      );
    }
  }, [
    setUploadStarted,
    clearFile,
    cancelToken,
    file,
    projectId,
    uploadStarted,
  ]);

  if (uploadFailed) {
    return <Text variant="body1">Opplasting misslykket</Text>;
  } else if (uploadComplete) {
    return <Text variant="body1">Ferdig</Text>;
  } else {
    return <LinearProgressWithLabel value={uploadProgress} />;
  }
}

export default FileUploadItem;
