/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Delivery API
 * This API manages the projects and docuements that are stored in the Ameta Document Delivery. The API used oAuth2 flow for authorization.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserCompanyRelation,
    UserCompanyRelationFromJSON,
    UserCompanyRelationToJSON,
} from '../models';

export interface UserCompanyRequest {
    userId: string;
}

/**
 * 
 */
export class UserCompanyApi extends runtime.BaseAPI {

    /**
     * Get company by user id
     */
    async userCompanyRaw(requestParameters: UserCompanyRequest): Promise<runtime.ApiResponse<UserCompanyRelation>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userCompany.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/users/{userId}/company`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCompanyRelationFromJSON(jsonValue));
    }

    /**
     * Get company by user id
     */
    async userCompany(requestParameters: UserCompanyRequest): Promise<UserCompanyRelation> {
        const response = await this.userCompanyRaw(requestParameters);
        return await response.value();
    }

}
