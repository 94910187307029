import Cookies, { CookieSetOptions } from "universal-cookie";

const cookies = new Cookies();

const cookieConfig: CookieSetOptions = {
  path: "/",
  domain: "ameta.app",
  sameSite: "lax",
  secure: true,
};

export function setCookie(name: string, value: any) {
  cookies.set(name, value, cookieConfig);
}
