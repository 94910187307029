/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Delivery API
 * This API manages the projects and docuements that are stored in the Ameta Document Delivery. The API used oAuth2 flow for authorization.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyProjectRelation,
    CompanyProjectRelationFromJSON,
    CompanyProjectRelationToJSON,
    NewProject,
    NewProjectFromJSON,
    NewProjectToJSON,
    Project,
    ProjectFromJSON,
    ProjectToJSON,
    ProjectDocumentRelation,
    ProjectDocumentRelationFromJSON,
    ProjectDocumentRelationToJSON,
    ProjectPointcloudRelation,
    ProjectPointcloudRelationFromJSON,
    ProjectPointcloudRelationToJSON,
    ShareProject,
    ShareProjectFromJSON,
    ShareProjectToJSON,
    UpdateProject,
    UpdateProjectFromJSON,
    UpdateProjectToJSON,
    UserProjectRelation,
    UserProjectRelationFromJSON,
    UserProjectRelationToJSON,
} from '../models';

export interface CreateProjectRequest {
    newProject: NewProject;
}

export interface DeleteProjectRequest {
    projectId: string;
}

export interface GetProjectRequest {
    projectId: string;
}

export interface ListDocumentsRequest {
    projectId: string;
}

export interface ListPointcloudsRequest {
    projectId: string;
}

export interface ListProjectCompaniesRequest {
    projectId: string;
}

export interface ListProjectUsersRequest {
    projectId: string;
}

export interface ShareProjectRequest {
    projectId: string;
    shareProject: ShareProject;
}

export interface UpdateProjectRequest {
    projectId: string;
    updateProject: UpdateProject;
}

/**
 * 
 */
export class ProjectApi extends runtime.BaseAPI {

    /**
     * Create a new project
     */
    async createProjectRaw(requestParameters: CreateProjectRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.newProject === null || requestParameters.newProject === undefined) {
            throw new runtime.RequiredError('newProject','Required parameter requestParameters.newProject was null or undefined when calling createProject.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/projects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewProjectToJSON(requestParameters.newProject),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create a new project
     */
    async createProject(requestParameters: CreateProjectRequest): Promise<string> {
        const response = await this.createProjectRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete a project
     */
    async deleteProjectRaw(requestParameters: DeleteProjectRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteProject.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/projects/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete a project
     */
    async deleteProject(requestParameters: DeleteProjectRequest): Promise<void> {
        await this.deleteProjectRaw(requestParameters);
    }

    /**
     * Get project element
     */
    async getProjectRaw(requestParameters: GetProjectRequest): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProject.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/projects/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     * Get project element
     */
    async getProject(requestParameters: GetProjectRequest): Promise<Project> {
        const response = await this.getProjectRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all documents for a project
     */
    async listDocumentsRaw(requestParameters: ListDocumentsRequest): Promise<runtime.ApiResponse<Array<ProjectDocumentRelation>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling listDocuments.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/projects/{projectId}/documents`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectDocumentRelationFromJSON));
    }

    /**
     * List all documents for a project
     */
    async listDocuments(requestParameters: ListDocumentsRequest): Promise<Array<ProjectDocumentRelation>> {
        const response = await this.listDocumentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all pointclouds for a project
     */
    async listPointcloudsRaw(requestParameters: ListPointcloudsRequest): Promise<runtime.ApiResponse<Array<ProjectPointcloudRelation>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling listPointclouds.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/projects/{projectId}/pointclouds`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectPointcloudRelationFromJSON));
    }

    /**
     * List all pointclouds for a project
     */
    async listPointclouds(requestParameters: ListPointcloudsRequest): Promise<Array<ProjectPointcloudRelation>> {
        const response = await this.listPointcloudsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get companies related to a project
     */
    async listProjectCompaniesRaw(requestParameters: ListProjectCompaniesRequest): Promise<runtime.ApiResponse<Array<CompanyProjectRelation>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling listProjectCompanies.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/projects/{projectId}/companies`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyProjectRelationFromJSON));
    }

    /**
     * Get companies related to a project
     */
    async listProjectCompanies(requestParameters: ListProjectCompaniesRequest): Promise<Array<CompanyProjectRelation>> {
        const response = await this.listProjectCompaniesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get users related to a project
     */
    async listProjectUsersRaw(requestParameters: ListProjectUsersRequest): Promise<runtime.ApiResponse<Array<UserProjectRelation>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling listProjectUsers.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/projects/{projectId}/users`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserProjectRelationFromJSON));
    }

    /**
     * Get users related to a project
     */
    async listProjectUsers(requestParameters: ListProjectUsersRequest): Promise<Array<UserProjectRelation>> {
        const response = await this.listProjectUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all projects for a company
     */
    async listProjectsRaw(): Promise<runtime.ApiResponse<Array<CompanyProjectRelation>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/projects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyProjectRelationFromJSON));
    }

    /**
     * List all projects for a company
     */
    async listProjects(): Promise<Array<CompanyProjectRelation>> {
        const response = await this.listProjectsRaw();
        return await response.value();
    }

    /**
     * Share project with another company
     */
    async shareProjectRaw(requestParameters: ShareProjectRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling shareProject.');
        }

        if (requestParameters.shareProject === null || requestParameters.shareProject === undefined) {
            throw new runtime.RequiredError('shareProject','Required parameter requestParameters.shareProject was null or undefined when calling shareProject.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/projects/{projectId}/share`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShareProjectToJSON(requestParameters.shareProject),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Share project with another company
     */
    async shareProject(requestParameters: ShareProjectRequest): Promise<string> {
        const response = await this.shareProjectRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a project
     */
    async updateProjectRaw(requestParameters: UpdateProjectRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling updateProject.');
        }

        if (requestParameters.updateProject === null || requestParameters.updateProject === undefined) {
            throw new runtime.RequiredError('updateProject','Required parameter requestParameters.updateProject was null or undefined when calling updateProject.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/projects/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProjectToJSON(requestParameters.updateProject),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Update a project
     */
    async updateProject(requestParameters: UpdateProjectRequest): Promise<string> {
        const response = await this.updateProjectRaw(requestParameters);
        return await response.value();
    }

}
