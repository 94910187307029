import React from "react";

import Text from "@smartinspection/ameta-frontend-components/display/Text";
import Table from "@smartinspection/ameta-frontend-components/display/Table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProjectPointcloudRelation } from "services/deliveryApi/models";

import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";

function PointcloudTable({
  pointclouds,
}: {
  pointclouds?: ProjectPointcloudRelation[];
}) {
  const theme = useTheme();

  const tableData =
    pointclouds &&
    pointclouds.map((pointcloud) => {
      let statusText = "";
      let statusTextColor: "success" | "error" | "info" = "info";

      switch (pointcloud.processStatus) {
        case "processing":
          statusText = "prosesserer punktsky...";
          break;
        case "complete":
          statusText = "ferdig prosessert";
          statusTextColor = "success";
          break;
        case "error":
          statusText = "prosessering mislykket";
          statusTextColor = "error";
          break;
      }
      return {
        icon: (
          <FontAwesomeIcon
            size="2x"
            color={theme.palette.warning.main}
            icon="cloud"
          />
        ),
        name: pointcloud.pointcloudName,
        status: (
          <Text
            sx={{ color: theme.palette[statusTextColor].main }}
            variant="body1"
          >
            {statusText}
          </Text>
        ),
        url: pointcloud.pointcloudS3Href,
      };
    });

  return (
    <Table
      onRowClick={(pointcloud: any) => {
        window.open(pointcloud.url, "_blank");
      }}
      headers={[
        { fieldName: "icon", name: "", align: "center" },
        { fieldName: "name", name: "Filnavn" },
        { fieldName: "status", name: "Status" },
      ]}
      data={tableData}
    />
  );
}

export default PointcloudTable;
